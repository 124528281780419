import React, { useCallback, useState, useEffect } from "react";
import {
  InkeepCustomTrigger,
  type InkeepCustomTriggerProps,
  type InkeepBaseSettings,
} from "@inkeep/uikit";

const SearchCustomTrigger: React.FC = () => {
  const [isOpen, setIsOpen] = useState<"AI_CHAT" | "SEARCH" | null>(null);

  const handleClose = useCallback(() => {
    setIsOpen(null);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "/") {
        setIsOpen("SEARCH");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const base_settings: InkeepBaseSettings = {
    optOutFunctionalCookies: true,
    apiKey: "43d24851dbbc933f45f58d5464abd900fae3477d1a6dfa11",
    integrationId: "clpkaj8g3000ms6015b1uov2b",
    organizationId: "org_An9MPtn9D3x2oIA5",
    organizationDisplayName: "Hookdeck",
    primaryBrandColor: "#0044CC",
    colorMode: {
      forcedColorMode:
        window !== undefined
          ? localStorage.getItem("theme") || "light"
          : "light",
    },
    theme: {
      tokens: {
        fonts: {
          heading: "'Inter'",
          body: "'Inter'",
          mono: "'JetBrains Mono', monospace",
        },
      },
    },
    customCardSettings: [
      {
        filters: {
          UrlMatch: {
            ruleType: "PartialUrl",
            partialUrl: "https://hookdeck.com/docs",
          },
        },
        searchTabLabel: "Docs",
      },
      {
        filters: {
          UrlMatch: {
            ruleType: "PartialUrl",
            partialUrl: "https://hookdeck.com/docs/api",
          },
        },
        searchTabLabel: "API",
      },
      {
        filters: {
          UrlMatch: {
            ruleType: "PartialUrl",
            partialUrl: "https://hookdeck.com/docs/guides",
          },
        },
        searchTabLabel: "Product Guides",
      },
      {
        filters: {
          UrlMatch: {
            ruleType: "PartialUrl",
            partialUrl: "https://hookdeck.com/webhooks/guides",
          },
        },
        searchTabLabel: "Webhook Guides",
      },
    ],
  };

  const settings: InkeepCustomTriggerProps = {
    isOpen: !!isOpen,
    onClose: handleClose,
    baseSettings: base_settings,
    modalSettings: {
      defaultView: isOpen as "SEARCH" | "AI_CHAT",
      isModeSwitchingEnabled: false,
      openShortcutKey: "/",
    },
    searchSettings: {
      tabSettings: {
        isAllTabEnabled: false,
        alwaysDisplayedTabs: [
          "Docs",
          "API",
          "Product Guides",
          "Webhook Guides",
        ],
      },
    },
  };

  return (
    <>
      <button onClick={() => setIsOpen("SEARCH")} className="search-bar">
        Search docs...
      </button>
      <button onClick={() => setIsOpen("AI_CHAT")} className="ask-ai">
        Ask AI
      </button>
      {typeof window !== undefined && <InkeepCustomTrigger {...settings} />}
    </>
  );
};

export default SearchCustomTrigger;
